// MorphAnimation.js
import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';

import logo from '../../assets/logo.png';
import grid from '../../assets/grid.svg'

gsap.registerPlugin(MorphSVGPlugin);

const MorphAnimation = () => {

    useEffect(() => {
        const tl = gsap.timeline();
    
        // Pause for 1 second
        tl.to("#circle", { duration: 1 })
    
          // Start all animations simultaneously after the 1 second pause
          .add([
            gsap.to(".grid-image", { duration: 1, opacity: 0, ease: "power2.out" }),
            gsap.to(".logo-image", { duration: 1, opacity: 1, y: "-=90", ease: "power2.out" }),
            gsap.to("#circle", { duration: 1, morphSVG: "#triangle" })
          ]);
    
    }, []);
    

    return (
        <div className="svg-container w-full h-full flex items-center justify-center ">
        <div>
            <div className="flex items-center justify-center" style={{height:'207px'}}>
            <img src={grid} width="204" height="207" alt="Grid" className="absolute grid-image" />
            <div >
          <svg  data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="117" height="102" viewBox="0 0 117 102">
           <path id="circle"  fill="none" stroke="#FC1D1D" strokeMiterlimit="10" strokeWidth="3px" d="m29.07,33.68l-.13.23c-12.86,22.27,3.21,50.12,28.93,50.12h.26c25.72,0,41.79-27.84,28.93-50.12l-.13-.23c-12.86-22.27-45.01-22.27-57.87,0h.01Z"/>
           </svg>
           <svg data-name="Layer 1"  xmlns="http://www.w3.org/2000/svg" width="117" height="102" viewBox="0 0 117 102" className="hidden">
           <path id="triangle" fill="none" stroke="#FC1D1D" strokeMiterlimit="10" strokeWidth="3px" d="m57.49,1.47L1.13,99.1c-.36.63.09,1.42.82,1.42h112.73c.73,0,1.18-.79.82-1.42L59.13,1.47c-.36-.63-1.27-.63-1.64,0Z"/>
           </svg>
            </div>
           </div>
           <div className="mt-32">
           <img src={logo} width="113" height="32" className="logo-image opacity-0" alt="Thirty Seconds to Mars" /></div>
           </div>
        </div>
       
    );
}

export default MorphAnimation;
