// ShareVisibilityContext.js

import React, { createContext, useState, useContext } from 'react';

const ShareVisibilityContext = createContext();

export const useShareVisibility = () => {
  return useContext(ShareVisibilityContext);
 
};

export const ShareVisibilityProvider = ({ children }) => {
  const [isShareVisible, setIsShareVisible] = useState(false);

  const toggleShareVisibility = () => {
    setIsShareVisible((prev) => !prev);
    console.log('Share visibility toggled:', !isShareVisible); // Add this log statement
   
  };

  return (
    <ShareVisibilityContext.Provider value={{ isShareVisible, toggleShareVisibility }}>
      {children}
    </ShareVisibilityContext.Provider>
  );
};
// ShareVisibilityContext.js
