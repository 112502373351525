import React, { useState } from 'react';
import {ReactComponent as ChevronDown} from '../../assets/chevron-down.svg';

const LegalPanel = (props) => {
  const {onSlideDown, isLegalPanelVisible} = props;
  const handleSlideDown = () => {

    if (onSlideDown) {
      onSlideDown(); // Call the onSlideDown function from props
     
    }
  };

  return (
    <div className="bg-black">
      <div className="">
      {isLegalPanelVisible && (
        <header className="fixed top-0 w-full">
          <div className="p-4" onClick={handleSlideDown}>
            <ChevronDown />
          </div>
        </header>
        )}
        {/* Add a container with a fixed height and overflow-y for scrolling */}
        <div className="page-extra-scrollable h-screen overflow-y-scroll ">
            {/* Content that can be scrolled */}
            <div className="pb-32 text-white px-4 text-xs">
 <h3 className="text-white text-xs uppercase text-center my-4">Privacy Policy for 'Loud Link'</h3>
            <p><i>Last Updated: 10 SEPTEMBER</i></p>
            <p>We respect your concerns about privacy and value the relationship we have with you. This Privacy Policy describes our approach to any information we may collect from you or which you may provide us when you visit our website.</p>
        <p>Information We Collect and How We Use It</p>
        <p>Anonymised Data Collection: We collect certain anonymous data to understand our website's performance. This includes:</p>
        <p>Geolocation</p>
        <p>The number of times the site is accessed</p>
        <p>Device information (e.g., type of device, operating system)</p>
        <p>Purpose of Data Collection: This data is collected to measure activity on our website and to improve its performance, ensuring we provide the best possible user experience.</p>
<p>Data Storage: Your data is stored securely. We use industry-standard security measures to prevent the loss, misuse, or alteration of the data under our control.</p>
<p>No Personal Information Collected</p>
<p>Loud Link does not collect, store, or process any personal identifiable information from its visitors. All the data we gather is completely anonymous.</p>
<p>Age Restrictions</p>
<p>We are committed to protecting the safety and privacy of young people using the internet. While our website is suitable for users of all ages, we strongly advise children under the age of 13 to obtain consent from a parent or guardian before accessing or using our site.</p>
 <p>Parents and guardians should be aware of and help enforce their children's online privacy. We encourage parents and guardians to spend time online with their children and to be familiar with the sites their children visit.</p> 
  <p>If you are a parent or guardian and believe 'Loud Link' might have inadvertently collected information from your child under the age of 13, please contact us immediately, and we will use our best efforts to promptly remove such information from our records.</p>
  <p>Safe Surf Policy for 'Loud Link'</p>
  <p><i>Last Updated: 10 SEPTEMBER</i></p>
<p>At 'Loud Link', we advocate for a safer internet experience for all users. We understand that the internet is a vast space, and while it offers a wealth of information and opportunities for connection, it also presents potential risks. Our Safe Surf Policy aims to provide guidelines and resources to ensure a more secure online experience for our users.</p>
<p>Be Cautious: Do not disclose personal information online to unfamiliar sources or websites.</p>
<p>Protect Your Device: Always have updated antivirus and malware protection software.</p>
<p>Be Aware of Cookies and Tracking: Understand website cookie policies and manage your browser settings to control your level of privacy.</p>
<p>Educate Yourself: Stay updated about online threats and safe online behaviors.</p>
<p>For Parents and Guardians: Monitor your children's online activity, educate them about online safety, and consider using parental control tools.</p>
<p>Remember, while 'Loud Link' aims to provide a safe environment, users are ultimately responsible for their actions and understanding of internet safety protocols.</p>
<p>Rights of California Residents</p>            
<p>Pursuant to the California Consumer Privacy Act (CCPA), California residents have the right to:</p>
<p>Know: Request disclosure of our data collection and sales practices in relation to your personal information, including the categories of personal information we collect, the source of the information, use, and, if applicable, who we share it with.</p>
<p>Request Deletion: Ask for deletion of your personal information (with certain exceptions).</p>
<p>Opt-Out of Sales: Direct a business to cease the selling of your personal information.</p>
<p>Non-Discrimination: Receive equal service and price, even if you exercise your rights under the CCPA.</p>
<p>Please note: As 'Loud Link' does not collect personal information, the aforementioned rights mainly serve an informative purpose. If you have questions or concerns regarding your rights as a California resident, please contact us.</p>
<p>Cookies Policy for 'Loud Link'</p>
<p>Last Updated: 10 SEPTEMBER</p>
<p>Cookies are small text files that are placed on your device to help the site provide a better user experience. At 'Loud Link', we use cookies to understand and save your preferences for future visits and to compile aggregate data about site traffic and site interaction.</p>
<p>What We Collect</p>
<p>While our cookies do not track personal information, they help us understand:</p>
<p>How visitors interact with our website</p>
<p>The effectiveness of our website performance</p>
<p>Why We Use Cookies</p>
<p>To Measure Activity: Understand how often our website is accessed, from which locations, and by which devices.</p>
<p>To Improve Performance: Gather insights to enhance user experience and make necessary improvements.</p>
<p>Managing Cookies</p>
<p>Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, please note that you may not be able to sign in or use some of the interactive features offered on our website.</p>
<p>Consent</p>
<p>By using our website, you agree to our use of cookies as described in this Cookies Policy.</p>
<p></p>

            </div>
            </div>
        </div>
    </div>

     
  );
};

export default LegalPanel;
