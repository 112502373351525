import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

const AudioContext = createContext();

export function useAudio() {
  return useContext(AudioContext);
}

export function AudioProvider({ children }) {
  const audioPlayerRef = useRef(null);
  const [currentPlayingAudio, setCurrentPlayingAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);

  useEffect(() => {
    audioPlayerRef.current = document.getElementById('audioPlayer');

  }, []);

  const playAudio = () => {
    if (audioPlayerRef.current) {
      setCurrentPlayingAudio(audioPlayerRef.current);
          if (!hasPlayed) {
    setHasPlayed(true);
  }
      if (audioPlayerRef.current.paused) {
           audioPlayerRef.current.play()
          .then(() => {
           // console.log('Resuming audio playback:', audioPlayerRef.current);
          })
          .catch((error) => {
            //console.error('Error playing audio:', error);
          });
      } else {
     //   console.log('Audio is already playing:', audioPlayerRef.current);
      }
      setIsPlaying(true);
    }
  };

  const pauseAudio = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.pause();
    //  console.log('Current Audio:', audioPlayerRef.current);
      setIsPlaying(false);
    }
  };

  const togglePlayback = () => {
    if (audioPlayerRef.current && audioPlayerRef.current.paused) {
      playAudio();
        if (!hasPlayed) {
    setHasPlayed(true);
  }
    } else {
      pauseAudio();
    }
    
  };

  return (
  <AudioContext.Provider value={{ currentPlayingAudio, playAudio, pauseAudio, togglePlayback, isPlaying, hasPlayed, setHasPlayed }}>
  {children}
</AudioContext.Provider>

  );
}
