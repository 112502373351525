import React, { useState, useEffect } from 'react';
import LoadingAnimation from '../loading-animation/loading-animation.component';
import {ReactComponent as TouchSvg} from '../../assets/touch-circle.svg'; // Import the SVG file

const handleMute = () => {
  const audioPlayer = document.getElementById('audioPlayer');
  audioPlayer.pause();
  //audioPlayer.removeEventListener('timeupdate', handleSync);
  audioPlayer.pause();
  console.log('clearing audio'); 
} 

const ThreeDViewer = ({ glbFilePath}) => {
    const modelRef = React.useRef();
    const [modelLoaded, setModelLoaded] = useState(false); // State to track model loading
    useEffect(() => {
    // Handles loading the events for <model-viewer>'s slotted progress bar

    const audioPlayer = document.getElementById('audioPlayer');
    const modelViewer = document.getElementById('holoViewer');

    const handleSync = () => {
      const audioPlayer = document.getElementById('audioPlayer');
      const modelViewer = document.getElementById('holoViewer');
      console.log('called'); 
      if (modelViewer && audioPlayer) {
        console.log('syncing 1... '+modelViewer.currentTime+" "+audioPlayer.currentTime)
          if (audioPlayer.currentTime !== modelViewer.currentTime) {
            // restart both model and sound
            console.log('syncing 2... '+modelViewer.currentTime+" "+audioPlayer.currentTime)
            modelViewer.currentTime = 0;
            audioPlayer.currentTime = 0;
          } else {
            modelViewer.currentTime = audioPlayer.currentTime;
          }
          audioPlayer.play();
          modelViewer.play();
      }
    };
    
    audioPlayer.addEventListener("seeked", handleSync());

    const onProgress = (event) => {
      const progressBar = event.target.querySelector('.progress-bar');
      const updatingBar = event.target.querySelector('.update-bar');
      updatingBar.style.width = `${event.detail.totalProgress * 100}%`;
      if (event.detail.totalProgress === 1) {
        progressBar.classList.add('hide');
        event.target.removeEventListener('progress', onProgress);
      } else {
        progressBar.classList.remove('hide');
      }
    };

    if (modelRef.current) {
      modelRef.current.addEventListener('progress', onProgress);
    }
      // Event listener for model load
    const onModelLoad = () => {
      setModelLoaded(true); // Set the state to indicate model is loaded
      modelRef.current.removeEventListener('load', onModelLoad); // Remove the event listener
    };

    if (modelRef.current) {
      modelRef.current.addEventListener('load', onModelLoad); // Add the event listener for model load
    }
    // Clean up the event listener when the component unmounts
    return () => {
      if (modelRef.current) {
        modelRef.current.removeEventListener('progress', onProgress);
      }
    };
  }, []);

    return (
        <div className="h-screen relative">
       
        <model-viewer id="holoViewer"
          class="model-viewer w-full h-full"
          src={glbFilePath+"?sound=https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/ar/30STM_HOLO_SOUNDBED.mp3"}
          ios-src="https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/ar/30STM_HOLOGRAM_02.reality" 
          ar="true"
          autoplay
          camera-controls 
          field-of-view="50deg" 
          camera-target="0m 0.94m 0m" 
          auto-rotate
          shadow-intensity="1" 
          exposure="0.5"
        >
        <div className="progress-bar" slot="progress-bar">
         <div className="update-bar"></div>
        </div>
   
        <button slot="ar-button" id="ar-button" onClick={handleMute} className="absolute bottom-0 z-50 mb-32 w-full flex justify-center"><div className="bg-mars-yellow flex text-black items-center py-2 px-4 rounded-full activateButton uppercase"><TouchSvg className="fill-current mr-2" />See in your space</div></button>
        </model-viewer>

        </div>

      
    )

  
}
export default ThreeDViewer ;