import React, { useRef, useState, useEffect } from 'react';
import { useAudio } from '../../audio-context'; // Import the useAudio hook
import './track-selector.component.css';
import {ReactComponent as SkipNextSvg} from '../../assets/skip-next.svg'; // Import the SVG file
import {ReactComponent as PauseSvg} from '../../assets/pause-mini.svg'; // Import the SVG file

import {ReactComponent as Share} from '../../assets/share.svg';

import SharePanel from '../share-panel/share-panel.component';
import { useShareVisibility } from '../../share-visibility-context'; // Import the ShareVisibilityContext

const TrackSelector = (props) => {

  const { config, formatType, trackData, releaseData, trackSelectorActive } = props;
  const { isPlaying, togglePlayback, playAudio, pauseAudio,hasPlayed, setHasPlayed } = useAudio();
  const sectionsRef = useRef([]);
  const observerRef = useRef(null); // Intersection Observer reference
  const videoRef = useRef(null); // Video element reference
  const audioRef = useRef(null);
  const [currentPlayingAudio, setCurrentPlayingAudio] = useState(null);
  const [globalVideoSrc, setGlobalVideoSrc] = useState("mp4/_01.mp4");
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [isShareVisible, setIsShareVisible] = useState(false);
  const { toggleShareVisibility } = useShareVisibility(); // Access the toggleShareVisibility function
  const [progress, setProgress] = useState(0);



  useEffect(() => {
    // Get the audio player element
    const audioPlayer = document.getElementById('audioPlayer');

if (audioPlayer) {
  var currentVolume = audioPlayer.volume; // This will give you the current volume (a value between 0 and 1)
  console.log('Current Volume:', currentVolume);
  audioPlayer.volume = 0.3;
  currentVolume = audioPlayer.volume;
  console.log('Current Volume:', currentVolume);
}

    const handleTimeUpdate = () => {
        // Ensure we have the duration and current time to avoid NaN results
        if (audioPlayer.duration && audioPlayer.currentTime) {
            const progressPercentage = (audioPlayer.currentTime / audioPlayer.duration) * 100;
            setProgress(progressPercentage);
        }
    };

    // Add the event listener
    audioPlayer.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
        // Cleanup: Remove the event listener when the component is unmounted
        audioPlayer.removeEventListener('timeupdate', handleTimeUpdate);
    };
}, []);  

  useEffect(() => {
   
    const handleIntersection = (entries) => {
      entries.forEach((entry, index) => {
   
        const myIndex = sectionsRef.current.indexOf(entry.target);
        if (entry.isIntersecting) {
          const selectorMusic = document.getElementById('selectorMusic');
          const selectorCommentry = document.getElementById('selectorCommentry');
          selectorMusic.classList.add('border-b-2');
          selectorCommentry.classList.remove('border-b-2');
          console.log('Entering section:', sectionsRef.current.indexOf(entry.target));
          setGlobalVideoSrc(trackData[myIndex].video_src);
          setCurrentSectionIndex(myIndex); // Update the active section index
            // Play the audio
     // Define the audioPlayer variable
     const audioPlayer = document.getElementById('audioPlayer');
     if (trackSelectorActive) {
      
     // Check if audioPlayer exists before using it
     if (audioPlayer) {
       audioPlayer.src = trackData[myIndex].audio_src;
       audioPlayer.addEventListener('canplay', () => {
        playAudio(audioPlayer);
        setCurrentPlayingAudio(audioPlayer);
       });
     } else {
       console.error('Audio player element not found in the DOM');
     }
    }
         }
      });
    };

    observerRef.current = new IntersectionObserver(handleIntersection, {
      threshold: 0.5
    });

    sectionsRef.current.forEach((section) => {
      observerRef.current.observe(section);
    });

    const setVideoSource = () => {
      const videoPlayer = document.getElementById('videoPlayer');
      if (videoPlayer) {
        videoPlayer.src = globalVideoSrc;
      }
    };

    setVideoSource();

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };

  }, [trackData, globalVideoSrc, currentPlayingAudio, trackSelectorActive]);

  const playTrack = (index) => {
  const audioPlayer = document.getElementById('audioPlayer');
  const videoPlayer = document.getElementById('videoPlayer');
  const selectorMusic = document.getElementById('selectorMusic');
  const selectorCommentry = document.getElementById('selectorCommentry');
  //audioPlayer.autoplay = true;
  selectorMusic.classList.add('border-b-2');
  selectorCommentry.classList.remove('border-b-2');
  videoPlayer.classList.remove('blur');
  videoPlayer.classList.add('noblur');
  audioPlayer.src = trackData[index].audio_src;
 
}

const playAlt = (index) => {
  const audioPlayer = document.getElementById('audioPlayer');
  const videoPlayer = document.getElementById('videoPlayer');
  const selectorMusic = document.getElementById('selectorMusic');
  const selectorCommentry = document.getElementById('selectorCommentry');
  //audioPlayer.autoplay = true;
  selectorMusic.classList.remove('border-b-2');
  selectorCommentry.classList.add('border-b-2');
  videoPlayer.classList.remove('noblur');
  videoPlayer.classList.add('blur');
  audioPlayer.src = trackData[index].alt_audio_src;
}

function handleEnded() {
  const audioPlayer = document.getElementById('audioPlayer');
  console.log('ended'+currentSectionIndex);
  const length = trackData.length - 1; 
  console.log("length"+length);

  if (audioPlayer.src === config.hologram_audio) { 
    console.log("hologram audio ended");
    audioPlayer.play(); 
  } else { 
    if (currentSectionIndex < length) {
      console.log('next');
      setCurrentSectionIndex(currentSectionIndex + 1);
      playTrack(currentSectionIndex + 1);
    } else { 
      console.log('reset');
      setCurrentSectionIndex(0);
      playTrack(0);
    }
    console.log('Entering section:', sectionsRef.current.indexOf(currentSectionIndex));
    setGlobalVideoSrc(trackData[currentSectionIndex].video_src);
    setCurrentSectionIndex(currentSectionIndex); // Update the active section index
  } 
}

      //////// Share stuff

      const toggleShare = () => {
      
          toggleShareVisibility(); // Toggle share visibility using the context
          // ... (other share-related logic)
       
        setIsShareVisible(!isShareVisible);
        const scrollSelector = document.getElementById('scrollSelector');
      
        if (scrollSelector) {
          if (isShareVisible===false) {
            //for some reason this works but we need to look into it caefully because I dont understand why it checks if false or not
            scrollSelector.classList.add('overflow-y-hidden');
            document.body.style.overflow = 'hidden'; // Disable scrolling
          } else {
            // Remove the class when isShareVisible is false
            scrollSelector.classList.remove('overflow-y-hidden');
            document.body.style.overflow = 'auto'; // Disable scrolling
          }
        } else {
          // Handle the case where scrollSelector is not found in the DOM
          console.error('scrollSelector element not found in the DOM');
        }
      };
  
  // Function to enable body scrolling
  const handleSlideDown = () => {
    setIsShareVisible(false);
    toggleShareVisibility();
    const scrollSelector = document.getElementById('scrollSelector');  
    scrollSelector.classList.remove('overflow-y-hidden');
    document.body.style.overflow = 'auto'; // Disable scrolling
  };
  //console.log("currentPlayingAudio", currentPlayingAudio);
  
  return (
    <div>
    <div id="trackSelectorWrapper" className="fixed top-0 z-20 pointer-events-none ">
     <div id="scrollSelector" className={`scroll-container z-10 p max-h-screen overflow-y-auto opacity-0 bg-black`}>

     <div id="typeSelector" className="absolute top-8 text-center z-50 w-full flex justify-center">
        <span onClick={() => playTrack(currentSectionIndex)} id="selectorMusic" className="border-b-2 mr-8 text-white block">Music</span>
        <span onClick={() => playAlt(currentSectionIndex)} id="selectorCommentry" className="text-white block">Discover</span>
  </div>

        {trackData.map((data, index) => (
          <section
            key={index}
            ref={(el) => (sectionsRef.current[index] = el)}
            data-section-index={index}
            className={`video-wrapper h-screen snap-start relative overflow-hidden w-screen rounded-2xl`}>
   
          <div className={`h-full ${data.color} bg-cover`} style={{ backgroundImage: `url(${data.poster})` }} >

          {/*
            {data.link && (
              <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                {data.link.startsWith("http") ? (
                  // Check if it's an HTTP URL
                  <a href={data.link} className="button-style bg-black text-white p-4 pointer-events-auto">
                    Go to external link
                  </a>
                ) : (
                  <button className="button-style pointer-events-auto">Go to Link</button>
                )}
              </div>
            )} */}
          
            {renderVideo(index, data.video_src)}
            <footer className="absolute bottom-0 pb-6 px-4 w-full">
              <div className="flex">
                <div className="mini-packshot bg-cover w-12 h-12 bg-white mr-4" style={{backgroundImage:`url(${releaseData[formatType]['packshot']})`}}></div>
                <div className="flex-1 text-white">
                <h3>{data.audio_title}  {data.audio_subtitle && <span> - {data.audio_subtitle}</span>}</h3>
                <h4>Thirty Seconds to Mars</h4>
                </div>
                {<div className="share-wrapper w-12 h-12 bg-black items-center justify-center flex rounded-full"  onClick={toggleShare}><Share /></div>}
              </div>
            </footer>
            </div>
          </section>
        ))}
             
      </div>
      <div>
      
      {<div id="shareModal" className={`modal-slideup ${isShareVisible ? 'active' : ''}`}><SharePanel trackData={trackData} releaseData={releaseData} selectedItem={currentSectionIndex} onSlideDown={handleSlideDown} isShareVisible={isShareVisible} /></div>}</div>
      </div>
    
    <div id="miniSelector" className={`fixed bottom-0 w-full px-4 z-10 pointer-events-auto ${releaseData[formatType]['buy_link'] ? 'buy-link-active' : ''} ${ hasPlayed ? 'slide-up-class' : ''}`}>

      <div className="">
                <audio id="audioPlayer" onEnded={handleEnded} playsInline>
                  <source ref={audioRef} src={trackData[currentSectionIndex].audio_src} type="audio/mp3" />
                  Your browser does not support the audio tag.
                </audio>
              </div>
      <div className="w-full relative rounded-full">
          <div className="bg-gray-900/80 flex text-white rounded-full gap-4 h-[3.75rem] items-center pr-2 w-full inner backdrop-blur-sm justify-between" >
            <div className="bg-white h-[3.75rem] w-[3.75rem] bg-cover rounded-full mr-4" style={{backgroundImage:`url(${releaseData[formatType]['packshot']})`}}></div>
            <div className="flex flex-1 gap-2"><div>{trackData[currentSectionIndex].audio_title} {trackData[currentSectionIndex].audio_subtitle && <span className="subheading"> - {trackData[currentSectionIndex].audio_subtitle}</span>}</div></div> 
            <div className="">
<button 
    className="hover:bg-white hover:text-black h-[3.125rem] w-[3.125rem] rounded-full flex items-center justify-center bg-black" 
    onClick={togglePlayback}
>
    {isPlaying ? <PauseSvg /> : <SkipNextSvg className="arrow-icon fill-current"/>}
</button></div>
          
         </div>
         

        </div>
      </div>
      <div id="progressBarWrapper" className="w-full bg-black fixed bottom-0 left-0 z-30">
      <div id="progressBar" className="transition-all duration-500 bg-mars-yellow" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );

  // Function to render video based on the condition
  function renderVideo(sectionIndex, videoSrc) {
    if (currentSectionIndex === sectionIndex) {
      // Render video for the active section
      return (
        <video id="videoPlayer"
          muted
          autoPlay
          loop
          playsInline
          className="absolute top-0 w-full h-full max-w-none object-cover rounded-2xl overflow-hidden"
          poster="/images/w3html5.gif"
          ref={videoRef}
        >
          <source type="video/mp4" src={videoSrc} />
          Your browser does not support the video tag.
        </video>
      );
    }
    return null; // Return null for inactive sections
  }
};

export default TrackSelector;
