import React, { useRef, useEffect } from 'react';
import Flickity from 'flickity';
import 'flickity/css/flickity.css';
import './image-slider.component.css';
import imagesLoaded from 'flickity-imagesloaded';

const ImageSlider = (props) => {
  const { imageData,  sliderId } = props;


  const flickityRef = useRef(null);

  const handleTxTButtonClick = (audioSrc,musicIndex) => {
    const audioPlayer = document.getElementById('audioPlayer');
      audioPlayer.autoplay = true;
      // If there's a currently playing audio, pause it
     
       // Set the new audio source
      audioPlayer.src = audioSrc;

           // Calculate the target section's position
  const targetSectionIndex = musicIndex; // Assuming musicIndex corresponds to data-section-index
  const sections = document.querySelectorAll('[data-section-index]');
  const targetSection = sections[targetSectionIndex];

  if (targetSection) {
    const targetPosition = targetSection.offsetTop; // Get the top offset of the target section
    const container = document.getElementById('scrollSelector');
   
    // Scroll to the desired position with smooth scrolling animation
    container.scrollTo({
      top: targetPosition
    });

    
  }
   

  }

  useEffect(() => {
    console.log('sliderId:', sliderId);
    console.log('imageData:', imageData);

    flickityRef.current = new Flickity(`#${sliderId} .carousel`, {
     
      prevNextButtons: false,
      imagesLoaded: true,
      contain: true,
      on: {
        ready: function() {
          //
        },
        change: function( index ) {
     
        }
      }
    });

    return () => {
      flickityRef.current.destroy();
    };

  }, [sliderId,imageData]);

  return (
    <div>
      <div id={sliderId}>
      <div  className="carousel">
      {/* Loop through trackData to render images as slides */}
      {imageData.map((item, index) => (
        (item.alt_audio === true || item.tracklist === false) && ( // Only render images that are not tracklist images
          <div key={index} className="carousel-cell">
            <div  onClick={() => handleTxTButtonClick(item.alt_audio_src,index)} className="inner relative">
            <img src={item.thumbnail} className="object-cover" alt={`Slide ${index + 1}`}/>
            {item.audio_title || item.alt_audio_subtitle ? (
        <div className="absolute left-0 top-0 text-xs p-3">
          {item.audio_title && <div className="title">{item.audio_title}</div>}
          {item.alt_audio_subtitle && <div className="subheading">{item.alt_audio_subtitle}</div>}
        </div>
            
      ) : null}
            </div>
          </div>
      )
        ))}
      </div>
      </div>
    </div>
  );
};

export default ImageSlider;
