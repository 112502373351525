// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#logo{
    width:197px;
    height:89px;
}`, "",{"version":3,"sources":["webpack://./src/components/share-panel/share-panel.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;AACf","sourcesContent":["#logo{\n    width:197px;\n    height:89px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
