import React, { useState, useEffect } from 'react';
import LoadingAnimation from '../loading-animation/loading-animation.component';
const ThreeDViewer = ({ glbFilePath, hologram}) => {
    const modelRef = React.useRef();
    const [modelLoaded, setModelLoaded] = useState(false); // State to track model loading
    console.log('hologram', hologram);
    useEffect(() => {
    // Handles loading the events for <model-viewer>'s slotted progress bar
    const onProgress = (event) => {
      const progressBar = event.target.querySelector('.progress-bar');
      const updatingBar = event.target.querySelector('.update-bar');
      updatingBar.style.width = `${event.detail.totalProgress * 100}%`;
      if (event.detail.totalProgress === 1) {
        progressBar.classList.add('hide');
        event.target.removeEventListener('progress', onProgress);
      } else {
        progressBar.classList.remove('hide');
      }
    };

    if (modelRef.current) {
      modelRef.current.addEventListener('progress', onProgress);
    }
      // Event listener for model load
    const onModelLoad = () => {
      setModelLoaded(true); // Set the state to indicate model is loaded
      modelRef.current.removeEventListener('load', onModelLoad); // Remove the event listener
    };

    if (modelRef.current) {
      modelRef.current.addEventListener('load', onModelLoad); // Add the event listener for model load
    }
    // Clean up the event listener when the component unmounts
    return () => {
      if (modelRef.current) {
        modelRef.current.removeEventListener('progress', onProgress);
      }
    };
  }, []);

    return (
        <div className="h-screen relative">
       
        <model-viewer
          class="model-viewer w-full h-full"
          src={glbFilePath}
          xr-environment=""
          alt="AR"
          autoplay
          poster=""
          camera-controls 
          disable-tap 
          field-of-view="40deg" 
          camera-target="0m 0.94m 0m" 
          interaction-prompt="none"
          auto-rotate 
          auto-rotate-delay="8000" 
          rotation-per-second="20deg"  
          interpolation-decay="100"
          exposure="0.5"   
          quick-look-browsers="safari chrome"
          shadow-intensity="1"
          ref={(ref) => {
            modelRef.current = ref;
          }}
        >

        <div className="progress-bar" slot="progress-bar">
         <div className="update-bar"></div>
        </div>
        <button slot="ar-button" id="ar-button" className="bg-black text-white p-4 mt-16">View in your space</button>
  
        </model-viewer>

        </div>

      
    )
}
export default ThreeDViewer;