const config = {
    BACKEND_URL: "https://api.loud.link",
    SOCKET_URL: "wss://socket.loud.link",
    hologram_glb: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/ar/30STM_HOLOGRAM.glb",
    hologram_audio: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/ar/30STM_HOLO_SOUNDBED.mp3",
  };
  
  const releaseData = { 
    "HMV" : { 
      buy_link: false,
      force_buy: false,
      packshot: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/packshots/COVER_07.jpg",
      glb: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/ar/30STM_Vinyl_Yellow.glb"
    },
    "OOH" : { 
      buy_link: true,
      force_buy: false,
      packshot: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/packshots/COVER_07.jpg",
      glb: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/ar/30STM_Vinyl_Orange_OOH.glb"
    },    
    "CD" : { 
      buy_link: false,
      force_buy: false,
      packshot: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/packshots/COVER_07.jpg",
      glb: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/ar/30STM_CD.glb"
    },
    "LP" : {
      buy_link: false,
      force_buy: false,
      packshot: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/packshots/COVER_09.jpg",
      glb: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/ar/30STM_Vinyl_Orange.glb"
    }
  }


  const inTheBoxImageData = {
    "HMV": [{
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/888072516458/6458_0.png'
    },
    {
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/888072516458/6458_1.png'
    },
    {
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/888072516458/6458_2.png'
    }],
    "OOH": [{
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/OOH/OOH_0.png'
    },
    {
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/OOH/OOH_1.png'
    }],    
    "CD": [{
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/888072536555/1.png'
    },
    {
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/888072536555/2.png'
    },
    {
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/888072536555/3.png'
    },
    {
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/888072536555/6555_0.png'
    }],
    "LP": [{
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/888072516441/6441_0.png'
    },
    {
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/888072516441/6441_1.png'
    },
    {
      tracklist: false, 
      thumbnail: 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/inthebox/2/888072516441/6441_2.png'
    }]
  };

  const buyData = [
    { 
      active: true, 
      store: "Official Store", 
      store_img: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/stores/logos_officialstore_light.svg", 
      product: "Exclusve Vinyl + Merch & Bundles", 
      link: "https://www.thirtysecondstomars.uk/",
    },{ 
      active: true, 
      store: "HMV", 
      store_img: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/stores/logos_hmv_light.svg", 
      product: "Exclusive Vinyl & CD", 
      link: "https://hmv.com/search?searchtext=thirty+seconds+to+mars+it%27s+the+end+of+the+world",
    },{ 
      active: true, 
      store: "Amazon", 
      store_img: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/stores/logos_amazon_light.svg", 
      product: "Amazon Exclusive Deluxe CD with Bonus Tracks", 
      link: "https://www.amazon.co.uk/World-Beautiful-Amazon-Exclusive-Deluxe/dp/B0C48DBS4X/ref=sr_1_1?keywords=thirty+seconds+to+mars&qid=1691767382&sprefix=thirty+second%2Caps%2C125&sr=8-1",
    },{ 
      active: true, 
      store: "Amazon", 
      store_img: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/stores/logos_amazon_light.svg", 
      product: "Amazon Exclusive LP with Alt Cover", 
      link: "https://www.amazon.co.uk/World-Beautiful-Amazon-Exclusive-Deluxe/dp/B0C48DBS4X/ref=sr_1_1?keywords=thirty+seconds+to+mars&qid=1691767382&sprefix=thirty+second%2Caps%2C125&sr=8-1",
    },{ 
      active: true, 
      store: "Amazon", 
      store_img: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/stores/logos_amazon_light.svg", 
      product: "Standard Vinyl & CD", 
      link: "https://www.amazon.co.uk/Its-End-World-Beautiful-Vinyl/dp/B0C3W2SSXP/ref=pd_vtp_h_pd_vtp_h_sccl_3/262-7378976-3467705?pd_rd_w=a9VbX&content-id=amzn1.sym.0024c6b5-da90-4821-b6c0-667e7c129058&pf_rd_p=0024c6b5-da90-4821-b6c0-667e7c129058&pf_rd_r=506FXHWNMQZKBH1Z56DZ&pd_rd_wg=OMU18&pd_rd_r=ad264bbb-bd9c-4fe1-aec7-0da39953b44d&pd_rd_i=B0C3W2SSXP&psc=1",
    },{ 
      active: true, 
      store: "Record Store", 
      store_img: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/stores/logos_recordstore_light.svg", 
      product: "Vinyl & CD", 
      link: "https://recordstore.co.uk/search?q=thirty+seconds+to+mars",
    },{ 
      active: true, 
      store: "The Sound of Vinyl", 
      store_img: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/stores/logos_thesoundofvinyl_light.svg", 
      product: "Vinyl & CD", 
      link: "https://thesoundofvinyl.com/products/its-the-end-of-the-world-but-its-a-beautiful-day-opaque-orange-vinyl-lp?_pos=1&_sid=9c12aace8&_ss=r",
    },{ 
      active: false, 
      store: "Amazon Music", 
      store_img: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/stores/logos_amazonmusic_light.svg", 
      link: "",
    },{ 
      active: false, 
      store: "Apple Music", 
      store_img: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/stores/logos_applemusic_light.svg", 
      link: "",
    },{ 
      active: false, 
      store: "Spotify ", 
      store_img: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/stores/logos_spotify_light.svg", 
      link: "",
    },{ 
      active: false, 
      store: "Deezer", 
      store_img: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/stores/logos_deezer_light.svg", 
      link: "",
    }
  ]

  const trackData = [
    {
      tracklist: true,
      audio_title: "Stuck",
      audio_subtitle: "", 
      audio_length: "3:02",
      alt_audio: true, 
      alt_audio_subtitle: "Behind the album",
      alt_audio_length: "0:52",
      alt_audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/behindthealbum/30STM_BTA_1.mp3",
      audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/audio/30STM_01.mp3",
      video_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/video/TRACK_01.mp4",
      poster: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/poster/TRACK_01.jpg",
      thumbnail: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/thumbnail/AC_THUMB_01.jpg",
      color: "bg-black",
      link_tiktok: "https://vm.tiktok.com/ZGJnSAjtT/",
      link: ""
      },{
      tracklist: true,
      audio_title: "Life Is Beautiful",
      audio_subtitle: "", 
      audio_length: "3:19",
      alt_audio: true, 
      alt_audio_subtitle: "Behind the album",
      alt_audio_length: "0:39",
      alt_audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/behindthealbum/30STM_BTA_2.mp3",
      audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/audio/30STM_02.mp3",
      video_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/video/TRACK_02.mp4",
      poster: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/poster/TRACK_02.jpg",
      thumbnail: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/thumbnail/AC_THUMB_02.jpg",
      color: "bg-black",
      link: ""
      },{
      tracklist: true,
      audio_title: "Seasons",
      audio_subtitle: "", 
      audio_length: "2:46",
      alt_audio: true, 
      alt_audio_subtitle: "Behind the album",
      alt_audio_length: "0:20",
      alt_audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/behindthealbum/30STM_BTA_3.mp3",
      audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/audio/30STM_03.mp3",
      video_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/video/TRACK_03.mp4",
      poster: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/poster/TRACK_03.jpg",
      thumbnail: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/thumbnail/AC_THUMB_03.jpg",
      color: "bg-black",
      link_tiktok: "https://vm.tiktok.com/ZGJnW7Pwn/",
      link: ""
    },{
      tracklist: true,
      audio_title: "Get Up Kid",
      audio_subtitle: "", 
      audio_length: "2:58",
      alt_audio: true, 
      alt_audio_subtitle: "Behind the album",
      alt_audio_length: "0:34",
      alt_audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/behindthealbum/30STM_BTA_4.mp3",
      audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/audio/30STM_04.mp3",
      video_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/video/TRACK_04.mp4",
      poster: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/poster/TRACK_04.jpg",
      thumbnail: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/thumbnail/AC_THUMB_04.jpg",
      color: "bg-black",
      link: ""
    },{
      tracklist: true,
      audio_title: "Love These Days",
      audio_subtitle: "", 
      audio_length: "3:01",
      alt_audio: true, 
      alt_audio_subtitle: "Behind the album",
      alt_audio_length: "0:24",
      alt_audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/behindthealbum/30STM_BTA_5.mp3",
      audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/audio/30STM_05.mp3",
      video_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/video/TRACK_05.mp4",
      poster: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/poster/TRACK_05.jpg",
      thumbnail: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/thumbnail/AC_THUMB_05.jpg",
      color: "bg-black",
      link: ""
    },{
      tracklist: true,
      audio_title: "World on Fire",
      audio_subtitle: "", 
      audio_length: "3:18",
      alt_audio: true, 
      alt_audio_subtitle: "Behind the album",
      alt_audio_length: "1:09",
      alt_audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/behindthealbum/30STM_BTA_6.mp3",
      audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/audio/30STM_06.mp3",
      video_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/video/TRACK_06.mp4",
      poster: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/poster/TRACK_06.jpg",
      thumbnail: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/thumbnail/AC_THUMB_06.jpg",
      color: "bg-black",
      link: ""
    },{
      tracklist: true,
      audio_title: "7:1",
      audio_subtitle: "", 
      audio_length: "2:45",
      alt_audio: true, 
      alt_audio_subtitle: "Behind the album",
      alt_audio_length: "0:26",
      alt_audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/behindthealbum/30STM_BTA_7.mp3",
      audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/audio/30STM_07.mp3",
      video_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/video/TRACK_07.mp4",
      poster: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/poster/TRACK_07.jpg",
      thumbnail: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/thumbnail/AC_THUMB_07.jpg",
      color: "bg-black",
      link: ""
    },{
      tracklist: true,
      audio_title: "Never Not Love You",
      audio_subtitle: "", 
      audio_length: "3:14",
      alt_audio: true, 
      alt_audio_subtitle: "Behind the album",
      alt_audio_length: "0:49",
      alt_audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/behindthealbum/30STM_BTA_8.mp3",
      audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/audio/30STM_08.mp3",
      video_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/video/TRACK_08.mp4",
      poster: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/poster/TRACK_08.jpg",
      thumbnail: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/thumbnail/AC_THUMB_08.jpg",
      color: "bg-black",
      link: ""
    },{
      tracklist: true,
      audio_title: "Midnight Prayer",
      audio_subtitle: "", 
      audio_length: "2:34",
      alt_audio: true, 
      alt_audio_subtitle: "Behind the album",
      alt_audio_length: "0:30",
      alt_audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/behindthealbum/30STM_BTA_9.mp3",
      audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/audio/30STM_09.mp3",
      video_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/video/TRACK_09.mp4",
      poster: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/poster/TRACK_09.jpg",
      thumbnail: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/thumbnail/AC_THUMB_09.jpg",
      color: "bg-black",
      link: ""
    },{
      tracklist: true,
      audio_title: "Lost These Days",
      audio_subtitle: "", 
      audio_length: "3:05",
      alt_audio: true, 
      alt_audio_subtitle: "Behind the album",
      alt_audio_length: "0:25",
      alt_audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/behindthealbum/30STM_BTA_10.mp3",
      audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/audio/30STM_10.mp3",
      video_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/video/TRACK_10.mp4",
      poster: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/poster/TRACK_10.jpg",
      thumbnail: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/thumbnail/AC_THUMB_10.jpg",
      color: "bg-black",
      link: ""
    },{
      tracklist: true,
      audio_title: "Avalanche",
      audio_subtitle: "", 
      audio_length: "3:24",
      alt_audio: true, 
      alt_audio_subtitle: "Behind the album",
      alt_audio_length: "0:18",
      alt_audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/behindthealbum/30STM_BTA_11.mp3",
      audio_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/audio/30STM_11.mp3",
      video_src: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/video/TRACK_11.mp4",
      poster: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/poster/TRACK_11.jpg",
      thumbnail: "https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/thumbnail/AC_THUMB_11.jpg",
      color: "bg-black",
      link: ""
    }
  ];

  export {config, inTheBoxImageData, releaseData, buyData, trackData};