import React, { useState } from 'react';
import { useAudio } from '../../audio-context'; // Path to your context file
//import './page-extra.component.css';
import packShot from '../../assets/pKppzUaR.jpg';

import {ReactComponent as ChevronDown} from '../../assets/chevron-down.svg';

const BuyPanel = (props) => {
  const { buyData, onSlideDown, isBuyPanelVisible} = props;
  const handleSlideDown = () => {

    if (onSlideDown) {
      onSlideDown(); // Call the onSlideDown function from props
     
    }
  };

  return (
    <div className="bg-black">
      <div className="">
      {isBuyPanelVisible && (
        <header className="fixed top-0 w-full">
          <div className="p-4" onClick={handleSlideDown}>
            <ChevronDown />
          </div>
        </header>
        )}
        {/* Add a container with a fixed height and overflow-y for scrolling */}
        <div className="page-extra-scrollable h-screen overflow-y-scroll">
            {/* Content that can be scrolled */}
            <div className="pb-32">
          
            <div className="w-full flex items-center justify-center p-8"><img src={packShot} className="drop-shadow-2xl mt-8 mx-8 w-full" width="316" height="316" alt="Packshot" /></div>
            <h3 className="text-white text-sm uppercase text-center mb-4">Pre-Order Now</h3>
            <div id="buyNowGrid" className="grid-cols-3 gap-x-4 gap-y-6 px-4 mb-1 items-center">  
                 {buyData.map((item, index) => ( 
                    item.active === true && (
                      <React.Fragment key={index}>
                    <div className="mini-packshot"><img src={item.store_img} className="w-full" width="auto" height="12" alt="Packshot" /></div>
                     <div className="flex-1 text-white">
                     <h3>{item.product}</h3>
                     </div>
                   <div><a href={item.link} className="bg-mars-yellow items-center py-2 px-4 rounded-md text-xs uppercase button">Buy Now</a></div>
                   </React.Fragment>
)))}
</div>
            </div>
            </div>
        </div>
    </div>

     
  );
};

export default BuyPanel;
