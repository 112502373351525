import React, { useState } from 'react';
import { useAudio } from '../../audio-context'; // Path to your context file
import './info-view.component.css';
import ImageSlider from '../image-slider/image-slider.component';
import {ReactComponent as TouchSvg} from '../../assets/touch-circle.svg'; // Import the SVG file
import {ReactComponent as ExpandARSvg} from '../../assets/expand-ar.svg';

import pressShot from '../../assets/press-shot.jpg';
import {ReactComponent as ExpandSvg} from '../../assets/expand.svg'; // Import the SVG file
import {ReactComponent as PlayButton} from '../../assets/play-button.svg';
import {ReactComponent as PauseButton} from '../../assets/pause.svg';
import { useShareVisibility } from '../../share-visibility-context'; // Import the ShareVisibilityContext

import PageExtra from '../page-extra/page-extra.component';

const InfoView = (props) => {
  const { config, formatType, inTheBoxImageData, trackData, releaseData, handleThreeDViewerButtonClick } = props;
  const { playAudio, pauseAudio, isPlaying } = useAudio(); // Access audio functions from the context
  const [currentPlayingAudio, setCurrentPlayingAudio] = useState(null); // Track the current playing audio element
  const [isPageExtraVisible, setIsPageExtraVisible] = useState(false);
  const { toggleShareVisibility } = useShareVisibility(); // Access the toggleShareVisibility function
  
  let firstTrack = 0;

  console.log("FT"+formatType);
  const togglePageExtra = () => {
    toggleShareVisibility();
    setIsPageExtraVisible(!isPageExtraVisible);
    if (isPageExtraVisible) {
      document.body.style.overflow = 'auto'; // Enable scrolling
    } else {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    }
  };

// Function to enable body scrolling
const handleSlideDown = () => {
  toggleShareVisibility();
  document.body.style.overflow = 'auto';
  setIsPageExtraVisible(false);
};

  const handleAudioPlay = (musicIndex) => {
    console.log("index"+musicIndex); 
    const audioPlayer = document.getElementById('audioPlayer');
    audioPlayer.autoplay = true;
    // If there's a currently playing audio, pause it
    firstTrack = musicIndex;
     // Set the new audio source
    audioPlayer.src = trackData[musicIndex].audio_src;

     // Calculate the target section's position
  const targetSectionIndex = musicIndex; // Assuming musicIndex corresponds to data-section-index
  const sections = document.querySelectorAll('[data-section-index]');
  const targetSection = sections[targetSectionIndex];

    console.log("FT"+firstTrack); 
  if (targetSection) {
    const targetPosition = targetSection.offsetTop; // Get the top offset of the target section
    const container = document.getElementById('scrollSelector');
   
    // Scroll to the desired position with smooth scrolling animation
    container.scrollTo({
      top: targetPosition
    });
  }
    if (audioPlayer) {
      playAudio(audioPlayer);
      setCurrentPlayingAudio(audioPlayer); // Set the current audio player
    }

  };

  const handleButtonClick = (glb,holo) => {
    handleThreeDViewerButtonClick(glb, holo);
  };

  const handleAudioPause = () => {
    if (currentPlayingAudio) {
      pauseAudio();
      currentPlayingAudio.pause();
    //  setCurrentPlayingAudio(null); // Reset the currently playing audio element
    }
  };

  //const arLink = document.getElementById('arLink');
  let showAR = false; 
  let showRel = '';
  let hrefAR = '';
  let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (isMobile) {
      // Detect iOS devices
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        showAR = true;
        showRel = 'ar'; 
        //arLink.classList.remove('hidden');
          hrefAR = 'https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/ar/30STM_HOLO_APPLE.reality';
          //arLink.setAttribute('rel', 'ar');
         // arLink.style.display = 'block';
      } 
      // Detect Android devices
      else if (/Android/i.test(navigator.userAgent)) {
        showAR = true;
        //arLink.classList.remove('hidden');
        hrefAR = 'intent://arvr.google.com/scene-viewer/1.0?file=https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/ar/30STM_HOLO_ANDROID.glb&sound=https://loudlink.nyc3.cdn.digitaloceanspaces.com/30stm/ar/30STM_HOLO_SOUNDBED.mp3&mode=ar_only&action=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;';
          //arLink.style.display = 'block';
      }
  } 

  let trackNumber = 1;

  return (
    <div>
      <section id="hero" className="px-4 mb-2">
       
        <div className="w-full flex items-center justify-center"><img src={releaseData[formatType]['packshot']} className="drop-shadow-2xl" width="316" height="316" alt="Hero" onClick={()=>handleButtonClick(releaseData[formatType]['glb'], false)} /></div>
        <div id="heroArLink" onClick={()=>handleButtonClick(releaseData[formatType]['glb'], false)}><ExpandSvg className="fill-current" /></div>
        
      </section>
    {/* <ImageSlider imageData={infoImageData} /> */}  
      <div className="bg-black text-mars-white">
      <section id="infoContent" className="pb-8 px-4">
      <h2 className="pt-10">It’s The End Of The World But It’s A Beautiful Day</h2>
      <div className="flex items-center">
        <div className="w-8 h-8 bg-white bg-cover rounded-full mr-4" style={{backgroundImage:`url(${pressShot})`}}></div>
        <div className="text-sm grow">THIRTY SECONDS TO MARS</div>
        <div id="bigPlay" className="flex justify-end cursor-pointer color-dynamic" onClick={() => handleAudioPlay(firstTrack)}> {isPlaying ? <PauseButton /> : <PlayButton />}</div>
      </div>
        <table id="trackList" className="w-full mt-4 text-sm">
        
          <tbody>

          {trackData.map((data, index) => (
            data.tracklist === true && data.audio_title && (
              <tr key={index} onClick={() => handleAudioPlay(index)}>
                <td className="h-[40px] w-8">{trackNumber++}</td>
                <td>
                <div className="text-mars-trackgrey hover:text-white">
                {data.audio_title}</div>
                </td>
              
                <td className="text-right text-mars-trackgrey">{data.audio_length}</td>
              </tr>
            )
          ))}
          </tbody>
        </table>
        </section>
        <div className="pb-10">
              <h2 className="p-4">Unlock: AR Hologram</h2>
       <a id="arLink" className={showAR ? '' : 'hidden'} href={hrefAR} rel={showRel}> 
       <img src="/images/thumbnail.jpg" alt="AR" />
          
       </a>
       </div>
                    <section className="pb-8 px-4">
        <h2 className="mb-4">About the record</h2>
        <p className="text-mars-grey">Thirty Seconds to Mars makes a triumphant return with their sixth studio album, ‘It’s The End Of The World But It’s A Beautiful Day’. The record heralds a new era for the band, one that not only explores the darker sides of the human experience but the hope as well, an arresting reminder that even in the face of seemingly impossible obstacles, there is still beauty to be found in the world.</p>
        <figure>
        <blockquote className="my-8" cite="">“Shannon and I wanted to make an album that speaks to who we are now.  The connective threads are a sense of optimism, celebration, vulnerability, and simplicity too. We didn’t want to return to something we’re familiar with, and I think we did that.”</blockquote>
        <figcaption>Jared Leto</figcaption>
        </figure>
        </section>
        <section className="large-carousel pb-8">
        <h2 className="mb-4 px-4">In the box</h2>
        <ImageSlider imageData={inTheBoxImageData[formatType]} sliderId="slider1" />
        </section>
        {<section className="mini-carousel pb-8">
        <h2 className="mb-4 px-4">Behind the album</h2>
        {/*<p className="mb-4 px-4">Get a glimpse into the album’s creation with audio from Jared Leto.</p>*/}
        <ImageSlider imageData={trackData} sliderId="slider2"/>
          </section>}
        <section className="pb-8 pt-8 px-4 cursor-pointer" onClick={togglePageExtra} >
          <div>
            <div className="relative">
            <img id="pressShot" src={pressShot} className="aspect-square w-full rounded-3xl " alt="Press shot" />
             <button className="bg-color-dynamic flex text-black activateButton absolute top-0 left-0 items-center py-2 px-4 rounded-full" >ABOUT THIRTY SECONDS TO MARS</button>  
            </div>
          </div>
        </section>        
      </div>
      <div id="aboutModal" className={`modal-slideup ${isPageExtraVisible ? 'active' : ''}`}>
        <PageExtra onSlideDown={handleSlideDown} isPageExtraVisible={isPageExtraVisible} />
      </div>
    </div>
  );
};

export default InfoView;
