// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#hero img{
    margin:68px 0 0;
}

#pressShot{

}

#heroArLink{
    position:absolute;
    right:0px;
    margin-right:1rem;
    margin-top:14px;
}

#infoContent{
    margin-top:68px;
}

#holagramPanel{
  
}

.large-carousel .carousel-cell{
    width:80%;
    pointer-events: none;
}
.large-carousel .carousel-cell .inner{
   
    background-color: #191919;
   
    padding:1rem;
   
    border-radius:1rem;
   
    margin-left:1rem;

}


.mini-carousel .carousel-cell{
    width:40%;
    

  
}

.mini-carousel .carousel-cell .inner{
    margin-left:1rem;
}



.mini-carousel .carousel-cell .inner img{
    border-radius:0.375rem;
    aspect-ratio:3/4;
}
/* Default style for PageExtra (hidden below the screen) */
#aboutModal {
    opacity: 0;
    position: fixed;
    bottom: -100%; /* Start it off-screen */
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out; /* Apply smooth animation */
    pointer-events: none;
  }
  
  /* Style when it's visible (slid up) */
  #aboutModal.active {
    opacity: 1;
    bottom: 0;
    pointer-events: auto;

  }

  #trackList tr td{
    border-bottom-width:1px;
    border-color:rgb(121 121 121 / var(--tw-border-opacity));
    --tw-border-opacity:0.5;
}

  #trackList tr td:first-child {
    border-bottom: none;
  }`, "",{"version":3,"sources":["webpack://./src/components/info-view/info-view.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;;AAEA;;AAGI;IAAA,iBAA4B;IAA5B,SAA4B;IAA5B,iBAA4B;IAC5B;AAD4B;;AAIhC;IACI,eAAe;AACnB;;AAEA;;AAEA;;AAEA;IACI,SAAS;IACT,oBAAoB;AACxB;AACA;;IAEI,yBAAyB;;IACzB,YAAU;;IACV,kBAAkB;;IAClB,gBAAW;;AAEf;;;AAGA;IACI,SAAS;;;;AAIb;;AAGI;IAAA;AAAW;;;;AAOX;IAAA,sBAAiB;IACjB;AADiB;AAIrB,0DAA0D;AAC1D;IACI,UAAU;IACV,eAAe;IACf,aAAa,EAAE,wBAAwB;IACvC,OAAO;IACP,QAAQ;IACR,gCAAgC,EAAE,2BAA2B;IAC7D,oBAAoB;EACtB;;EAEA,sCAAsC;EACtC;IACE,UAAU;IACV,SAAS;IACT,oBAAoB;;EAEtB;;EAGE;IAAA,uBAAkD;IAAlD,wDAAkD;IAAlD;AAAkD;;EAIpD;IACE,mBAAmB;EACrB","sourcesContent":["#hero img{\n    margin:68px 0 0;\n}\n\n#pressShot{\n\n}\n\n#heroArLink{\n    @apply absolute right-0 mr-4;\n    margin-top:14px;\n}\n\n#infoContent{\n    margin-top:68px;\n}\n\n#holagramPanel{\n  \n}\n\n.large-carousel .carousel-cell{\n    width:80%;\n    pointer-events: none;\n}\n.large-carousel .carousel-cell .inner{\n   \n    background-color: #191919;\n    @apply p-4;\n    @apply rounded-2xl;\n    @apply ml-4;\n\n}\n\n\n.mini-carousel .carousel-cell{\n    width:40%;\n    \n\n  \n}\n\n.mini-carousel .carousel-cell .inner {\n    @apply ml-4;\n \n}\n\n\n\n.mini-carousel .carousel-cell .inner img{\n    @apply rounded-md;\n    @apply aspect-[3/4];\n   \n}\n/* Default style for PageExtra (hidden below the screen) */\n#aboutModal {\n    opacity: 0;\n    position: fixed;\n    bottom: -100%; /* Start it off-screen */\n    left: 0;\n    right: 0;\n    transition: all 0.3s ease-in-out; /* Apply smooth animation */\n    pointer-events: none;\n  }\n  \n  /* Style when it's visible (slid up) */\n  #aboutModal.active {\n    opacity: 1;\n    bottom: 0;\n    pointer-events: auto;\n\n  }\n\n  #trackList tr td {\n    @apply border-b border-mars-grey border-opacity-50;\n\n  }\n\n  #trackList tr td:first-child {\n    border-bottom: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
