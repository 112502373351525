import React, { useState } from 'react';
import { useAudio } from '../../audio-context'; // Path to your context file
//import './page-extra.component.css';
import pressShot from '../../assets/press-shot.jpg';

import {ReactComponent as ChevronDown} from '../../assets/chevron-down.svg';


const PageExtra = (props) => {
  const { onSlideDown, isPageExtraVisible } = props;
  const handleSlideDown = () => {

    if (onSlideDown) {
      onSlideDown(); // Call the onSlideDown function from props
     
    }
  };

  return (
    <div className="bg-black">
      <div className="">
      {isPageExtraVisible && (
        <header className="fixed top-0 w-full">
          <div className="p-4" onClick={handleSlideDown}>
            <ChevronDown />
          </div>
        </header>
        )}
        {/* Add a container with a fixed height and overflow-y for scrolling */}
        <div className="page-extra-scrollable h-screen overflow-y-scroll">
            {/* Content that can be scrolled */}
            <img id="pressShot" src={pressShot} className="w-full" alt="Press shot" />
                <div className="px-4 pt-8 pb-36">
                    <h2 className="mb-4 text-white">About Thirty Seconds to Mars</h2>
                    <p className="text-mars-grey mb-4">Thirty Seconds to Mars makes a triumphant return with their sixth studio album, ‘It’s The End Of The World But It’s A Beautiful Day’. The record heralds a new era for the band, one that not only explores the darker sides of the human experience but the hope as well, an arresting reminder that even in the face of seemingly impossible obstacles, there is still beauty to be found in the world.</p>
                    <ul className="text-white text-sm uppercase">
                        <li><a target="_blank" href="https://www.instagram.com/30SECONDSTOMARS/">Instagram</a></li>
                        <li><a target="_blank" href="https://twitter.com/30SECONDSTOMARS">Twitter</a></li>
                        <li><a target="_blank" href="https://www.facebook.com/thirtysecondstomars">Facebook</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

     
  );
};

export default PageExtra;
