import './App.css';
import io from 'socket.io-client';
import axios from 'axios';

import React, { useState, useEffect } from 'react';
import ThreeDViewer from './components/three-d-viewer/three-d-viewer.component';
import HologramViewer from './components/hologram-viewer/hologram-viewer.component';

import TrackSelector from './components/track-selector/track-selector.component';
import InfoView from './components/info-view/info-view.component';
import {AudioProvider} from './audio-context'; // Path to your context file
import {ReactComponent as TrackSelectorIcon} from './assets/star.svg';
import starImage from './assets/star.svg';
import {ReactComponent as Heart} from './assets/heart.svg';
import {ReactComponent as ChevronDown} from './assets/chevron-down.svg';
import {ReactComponent as TinyUpArrow} from './assets/tiny-up-arrow.svg';
import { ShareVisibilityProvider, useShareVisibility } from './share-visibility-context';
import {config, inTheBoxImageData, releaseData, buyData, trackData} from './config';

import BuyPanel from './components/buy-panel/buy-panel.component'; 
import LegalPanel from './components/legal-panel/legal-panel.component'; 
import LoadingAnimation from './components/loading-animation/loading-animation.component';

const socket = io(config.SOCKET_URL);

let formatType = "OOH"; 

function sendToAnalytics(metric) {
  const body = JSON.stringify(metric);
  const url = config.BACKEND_URL+'/analytics/ ';
  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, method: 'POST', keepalive: true });
  }
}

function App() {
  
  const [uri, setUri] = useState('');
  const [exists, setExists] = useState(null);
  const [flash, setFlash] = useState(false);

  useEffect(() => {

    socket.on('like', (data) => {
      setFlash(true);
      setTimeout(() => setFlash(false), 1000); 
    });

  });

  useEffect(() => {
    const path = window.location.pathname;
    setUri(path);
    if (!path.includes("privacy-legals") ) {
      checkURI(path);
    }
    console.log("path:"+path);
    if (path.includes("privacy-legals") ) {
      toggleLegal(); 
    } else if (path.includes("cd") ||path.includes("cd2") || path.includes("pos")) {
      formatType = 'CD';
    } else if (path.includes("OOH") || path.includes("ooh")) {
      formatType = 'OOH';
    } else if (path.includes("hmv") || path.includes("lp2")) {
      formatType = 'HMV';      
    } else { 
      formatType = 'LP';
    }


  }, []);  
  
  const handleLike = () => {
    socket.emit('like');
  };
  
  function checkColour(elementName, colour) {
    let elements = document.getElementsByClassName(elementName);
    for (var i = 0; i < elements.length; i++) {
        elements[i].style.color=colour;
    }
}

function checkBgColour(elementName, colour) {
  let elements = document.getElementsByClassName(elementName);
  for (var i = 0; i < elements.length; i++) {
      elements[i].style.backgroundColor=colour;
      elements[i].setAttribute("fill", colour)
  }
}

  const checkURI = async (uriToCheck) => {
    try {
      if (uriToCheck !== undefined && uriToCheck !== '' && uriToCheck !== '/') {
        const response = await axios.get(config.BACKEND_URL+'/check-uri'+uriToCheck);
        setExists(response.data.exists);
        console.log(uriToCheck); 
        console.log("its:"+response.data.exists); 
        if (response.data.exists === false) {
          window.location.replace('https://mars.loud.link/OOH');
        } else { 
          const colour = "#"+response.data.colour;
          checkColour('color-dynamic',colour);
          checkBgColour('bg-color-dynamic',colour);
          document.body.style = 'background: '+colour+'!important;';

          document.querySelector('meta[name="theme-color"]').setAttribute('content',  colour);

          console.log("colour:"+colour);
        }
      } 
      if (uriToCheck === '' || uriToCheck === '/' || exists === false) {
       window.location.replace('https://mars.loud.link/OOH');
      }
    } catch (error) {
      console.error("Error checking URI:", error);
    }
  };

  const { isShareVisible } = useShareVisibility(); // Ensure it's at the top level

  const [showThreeDViewer, setShowThreeDViewer] = useState(false);

  const [showTrackSelectorButton, setShowTrackSelectorButton] = useState(true);
  const [trackSelectorActive, setIsTrackSelectorActive] = useState(false);
  const [glbFilePath, setGlbFilePath] = useState(null); // Initial GLB file path
  const [hologram, setHologram] = useState(null); // Is it the hologram version?

  const [isBuyPanelVisible, setisBuyPanelVisible] = useState(false);
  const [isLegalPanelVisible, setisLegalPanelVisible] = useState(false);

  const { toggleShareVisibility } = useShareVisibility(); // Access the toggleShareVisibility function

  const [isLoading, setIsLoading] = useState(true);
 
  const toggleBuy = () => {
    toggleShareVisibility();
    setisBuyPanelVisible(!isBuyPanelVisible);
    if (isBuyPanelVisible) {
      document.body.style.overflow = 'auto'; // Enable scrolling
    } else {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    }
  };

  const toggle3dViewer = () => {
    setShowThreeDViewer(false); 
    setHologram(false);
    const audioPlayer = document.getElementById('audioPlayer');
    console.log('remove event listener'); 
    audioPlayer.pause(); 
  }; 

  const toggleLegal = () => {
    toggleShareVisibility();
    console.log('Legals'); 
    setisLegalPanelVisible(!isLegalPanelVisible);
    if (isLegalPanelVisible) {
      document.body.style.overflow = 'auto'; // Enable scrolling
    } else {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    }
  };

  const handleSlideDown = () => {
    toggleShareVisibility();
    document.body.style.overflow = 'auto';
    setisBuyPanelVisible(false);
    setisLegalPanelVisible(false);
  };

  const handleThreeDViewerButtonClick = (gbl, holo) => {

    
    if (holo) {
    setHologram(true); // Set hologram state to true
    setShowThreeDViewer(false); 
    console.log('hologram view called')
    const audioPlayer = document.getElementById('audioPlayer');
  audioPlayer.src = config.hologram_audio;
  audioPlayer.play();
  } else {
    // If holo is false, hide the HologramViewer
    setHologram(false); // Set hologram state to false
    setShowThreeDViewer(!showThreeDViewer); // Toggle the ThreeDViewer
    console.log('three d view called')
  }

    setGlbFilePath(gbl); 
    setIsTrackSelectorActive(false);
  };

  const handleAudioControl = (audioAction) => {
    // Logic to control audio playback
    // You can access the TrackSelector's audio playback functions here
  };
  
  const infoViewButtonClick = () => {
  setShowThreeDViewer(false);
  setShowTrackSelectorButton(true); // Show the trackSelectorButton
  const miniSelector = document.getElementById('miniSelector');
  const scrollSelector = document.getElementById('scrollSelector');
  document.body.style.overflow = 'auto';
  if (scrollSelector) {
    scrollSelector.classList.add('opacity-0');
    scrollSelector.classList.remove('pointer-events-auto');
    miniSelector.classList.remove('opacity-0');
    miniSelector.classList.add('pointer-events-auto');
  }
};

  const handleTrackSelectorButtonClick = () => {
    setIsTrackSelectorActive(true);
    document.body.style.overflow = 'hidden';
    const audioPlayer = document.getElementById('audioPlayer');
    //audioPlayer.play();
    audioPlayer.autoplay = true;
    setShowThreeDViewer(false);
    setShowTrackSelectorButton(false); // Hide the trackSelectorButton

    const miniSelector = document.getElementById('miniSelector');
    const scrollSelector = document.getElementById('scrollSelector');

    if (scrollSelector) {
      scrollSelector.classList.remove('opacity-0');
      scrollSelector.classList.add('pointer-events-auto');
      miniSelector.classList.add('opacity-0');
      miniSelector.classList.remove('pointer-events-auto'); 
    }
};

useEffect(() => {
  setTimeout(() => {
    setIsLoading(false);
  }, 3000); // for example, 3 seconds delay
}, []);

  return (  
    <div className="App">

 {isShareVisible ? null : (
<header id="appHeader" className="App-header top-0 fixed z-50 text-white w-full mix-blend-difference">
{releaseData[formatType]['force_buy'] === false && (
      <ul className="flex gap-4 justify-between">
          {/* <li><button id="infoViewButton" onClick={infoViewButtonClick} className="p-3"></button></li> */} 
          <li>
          {(showThreeDViewer || hologram) ? (
  <button
    id="closeThreeDViewerButton"
    className="p-3"
    onClick={() => toggle3dViewer()}>
      Close
  </button>
) : (
  showTrackSelectorButton ? (
    <button
      id="trackSelectorButton"
      className="p-4"
      onClick={handleTrackSelectorButtonClick}>
       <TrackSelectorIcon />
    </button>
  ) : (
    <button id="InfoViewButton" className="px-4 pt-6" onClick={infoViewButtonClick}>
      <ChevronDown />
    </button>
  )
)}

          </li>

          <li><button className={`p-4 ${flash ? 'flash' : ''}`} onClick={handleLike} id="likeButton"><Heart /></button></li>
        </ul>
)} 
      </header>
      )}
      
        <div>
        <InfoView config={config} formatType={formatType} inTheBoxImageData={inTheBoxImageData} trackData={trackData} releaseData={releaseData} controlAudio={handleAudioControl} handleThreeDViewerButtonClick={handleThreeDViewerButtonClick}/>
      {releaseData[formatType]['force_buy'] === false && (
      
          <TrackSelector config={config} formatType={formatType} trackData={trackData} releaseData={releaseData} controlAudio={handleAudioControl} trackSelectorActive={trackSelectorActive}/>
    
       )}
      {releaseData[formatType]['buy_link'] === true && (
      <a href="https://found.ee/MARS_EOTWBD" target="_blank" id="buyButton" className="fixed bottom-0 left-0 w-full flex items-center justify-center text-xs uppercase bg-white font-bold">BUY NOW</a>
 )}
 {releaseData[formatType]['force_buy'] ? (
      <div id="buyModal" className="active">
      <BuyPanel buyData={buyData} onSlideDown={handleSlideDown} isBuyPanelVisible={isBuyPanelVisible} />
    </div>
 ) : ( 
        <div id="buyModal" className={`modal-slideup z-30 ${isBuyPanelVisible ? 'active' : ''}`}>
          <BuyPanel buyData={buyData} onSlideDown={handleSlideDown} isBuyPanelVisible={isBuyPanelVisible} />
        </div>
)}

      <div id="legalModal" className={`modal-slideup z-40 ${isLegalPanelVisible ? 'active' : ''}`}>
          <LegalPanel onSlideDown={handleSlideDown} isLegalPanelVisible={isLegalPanelVisible} />
        </div>
   
      </div>

    
      {(showThreeDViewer || hologram) && (
        <div
          id="threeDViewerWrapper"
          className="fixed top-0 bg-black w-full z-20"
        > 
       
       {hologram ? (
      // Render the HologramViewer component when 'hologram' is true
      <HologramViewer glbFilePath={glbFilePath} hologram={hologram} />
    ) : (
      // Render the ThreeDViewer component when 'hologram' is false
      <ThreeDViewer glbFilePath={glbFilePath} hologram={hologram} />
    )}
          
     
        </div>
      )}

      
      <footer className="bg-black text-mars-grey bottom-0 w-full z-50 px-4 pt-4 pb-32">
      <p>℗ & &copy; 2023 Thirty Seconds to Mars. Under exclusive license to Concord Records.  All rights reserved.</p>
      
        <div className="flex w-full justify-between"><div><p>&copy; 2023 <a rel="noreferrer" target="_blank" className="color-dynamic">LOUD.LINK</a> by Loud&reg; </p></div>
        <div onClick={toggleLegal} className="flex items-center"><div className="mr-2"><TinyUpArrow /></div><div><p>Privacy &amp; Legals</p></div></div></div>
       
      </footer>
    
      <div className={`fixed w-screen h-screen bg-black top-0 z-50 ${!isLoading ? 'fade-out' : ''}`}>
  <LoadingAnimation />
</div>
    
      </div>
  );
}

export { App, sendToAnalytics };
