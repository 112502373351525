import React, { useState } from 'react';
import { useAudio } from '../../audio-context'; // Path to your context file
//import './page-extra.component.css';
import './share-panel.component.css'
import logo from '../../assets/logo.png';

import {ReactComponent as ChevronDown} from '../../assets/chevron-down.svg';

const SharePanel= (props) => {
  const { trackData, releaseData, selectedItem, onSlideDown, isShareVisible } = props;

  const handleSlideDown = () => {
      console.log('down arrow clicked');
    if (onSlideDown) {
      onSlideDown(); // Call the onSlideDown function from props
     
    }
  };
 
  const [linkText, setLinkText] = useState("Copy Link");

  const handleCopy = (event) => {
    event.preventDefault();
    const hrefValue = event.currentTarget.getAttribute('href');
    navigator.clipboard.writeText(hrefValue).then(() => {
      setLinkText("Link Copied");
    }).catch(() => {
      console.error('Failed to copy the link.');
    });
  };

  return (
    <div className="bg-black">
      <div className="">
      {isShareVisible && (
        <header className="fixed top-0 w-full z-100 pointer-events-auto">
          <div className="p-4" onClick={handleSlideDown}>
            <ChevronDown />
          </div>
        </header>
        )}
        {/* Add a container with a fixed height and overflow-y for scrolling */}
        <div className="page-extra-scrollable h-screen overflow-y-scroll">
            {/* Content that can be scrolled */}
            <div className="w-full min-h-full flex flex-col justify-center items-center"> 
          <div>   <img id="logo" src={logo} className="mb-16" alt="Thirty Seconds to Mars" /></div>
                <div className="p-4">
                    <ul className="text-white text-sm uppercase grid grid-cols-2 gap-3">
                        {trackData[selectedItem].link_tiktok ? <li className=" bg-mars-grey p-9 flex justify-center items-center rounded"><a target="_blank" href={trackData[selectedItem].link_tiktok}>USE TIKTOK SOUND</a></li> : null }
                        <li className="bg-mars-grey p-9 flex justify-center items-center rounded"><a id="copyInput" href={window.location.href} onClick={handleCopy}>{linkText}</a></li>
                        <li className="bg-mars-grey p-9 flex justify-center items-center rounded"><a data-action="share/whatsapp/share" href={"whatsapp://send?text=Preview "+trackData[selectedItem].audio_title+" by Thirty Seconds To Mars "+window.location.href}>WHATSAPP</a></li>
                        <li className="bg-mars-grey p-9 flex justify-center items-center rounded"><a href={"sms:?+0&body=Preview "+trackData[selectedItem].audio_title+" by Thirty Seconds To Mars "+window.location.href}>SMS</a></li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
    </div>

     
  );
};

export default SharePanel;
